import React from 'react'

function Page404() {
  return (
    <div>
       <div className="container-xl">
       <img src="https://colorlib.com/wp/wp-content/uploads/sites/2/404-error-page-templates.jpg" className='w-100' alt="" />
       </div>
    </div>
  )
}

export default Page404